import React from 'react';
import ContactForm from './contact/ContactForm';

const Contact = () =>
(
  <div className="container is-fluid" style={{ marginTop: 60 }}>
    <div className="notification">
      <ContactForm />
    </div>
  </div>
);
export default Contact;
